import * as dotenv from "dotenv";

dotenv.config({
  path: process.env.ENV_FILE,
  encoding: "utf8",
});

const constants = {};

constants.DEFAULT_URL =
  process.env.REACT_APP_DEFAULT_URL ||
  "https://beta-api-pa.polismall.id";
constants.DEFAULT_URL_PUBLIC =
  process.env.REACT_APP_DEFAULT_URL_PUBLIC ||
  "https://demo-api.polismall.com";
constants.API_KEY =
  process.env.REACT_APP_API_KEY ||
  "45z3Yfz1r1RzKWzEaBaW0NYr1mzX4Wx1tMKKz3Yfz12udFK1DEqvYTZMuLYjAvcC58f40NYr1mzX4z3Yfz1r1RzKWzEaBaW0NYr1mzX4Wx1tMKKz3Yfz12udFK1DEqvYTZMuLYjAvcC58fWx1tMK51r1RzKWzEaBaWK";

constants.HTTP_STATUS_SUCCESS = 200;

export default constants;
