import React from "react"
import { MDBIcon } from "mdbreact"
import "./Button.css"

export default function PrimaryButton({
  loading,
  text,
  small,
  type,
  icon,
  onClick,
}) {
  const styles = (small) => {
    return small ? "root small" : "root"
  }

  return (
    <button
      className={styles(small)}
      disabled={loading}
      type={type}
      onClick={onClick}
    >
      {loading ? (
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <>
          <span>{text}</span>
          {icon && <MDBIcon icon={icon} className="ml-2"></MDBIcon>}
        </>
      )}
    </button>
  )
}
