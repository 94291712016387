import { combineReducers } from "redux";
import bookingReducer from "./booking";
import historyReducer from "./history";
import userReducer from "./user";

const rootReducers = combineReducers({
  bookingStore: bookingReducer,
  historyStore: historyReducer,
  userStore: userReducer,
});

export default rootReducers;
