import React from "react"
import { MDBContainer, MDBFooter, MDBRow, MDBCol } from "mdbreact"

export default function Footer() {
  return (
    <MDBFooter className="create-bg-primary">
      <MDBContainer className="py-5 text-center text-md-left">
        <MDBRow>
          <MDBCol md="6">
            <h5 className="font-weight-bold mb-4 footer-heading">
              POLISMALL POWERED BY <br /> PT. PIALANG ASURANSI <br /> PROVIS
              MITRA SINERGI
            </h5>
            <p>We Had Helped Many People, and Still Adding Up.</p>
          </MDBCol>
          <MDBCol md="6">
            <h5 className="font-weight-bold mb-4">CONTACT</h5>
            <p>(021) 2967 2495/ 2496/ 2497</p>
            <p>service@provisms.com</p>
            <p className="mb-0">
              Dipo Business Center, Rukan B-02 <br /> Jl. Gatot Subroto Kav.
              50-52 Jakarta 10260
            </p>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <MDBContainer>
        <hr className="border-white m-0" />
      </MDBContainer>
      <MDBContainer
        className="text-center font-small py-3"
        style={{ opacity: 0.75 }}
      >
        &copy; {new Date().getFullYear()} Copyright:{" "}
        <span className="font-weight-bold d-block d-md-inline">
          PT. Pialang Asuransi Provis Mitra Sinergi
        </span>
      </MDBContainer>
    </MDBFooter>
  )
}
