import React from "react"
import { MDBRow, MDBCol } from "mdbreact"
import Woman from "../img/woman.svg"
import Bg from "../img/bg.svg"

export default function Jumbotron() {
  return (
    <section className="create-jumbotron">
      <img src={Woman} alt="woman paper" className="woman"></img>
      <img src={Bg} alt="gradient background" className="bg"></img>

      <MDBRow>
        <MDBCol md="6">
          <h1 className="font-weight-bold">
            POLISMALL
            <br />
            PERSONAL ACCIDENT
          </h1>
          <p className="mb-0 lead">We provide Insurance to let you at ease.</p>
        </MDBCol>
      </MDBRow>
    </section>
  )
}
