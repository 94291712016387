import React, { useEffect, useState, useRef } from "react"
import { Link, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { setShowNotif } from "../../store/actions"
import {
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBIcon,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBNotification,
} from "mdbreact"
import Calendar from "../../icons/calendar.svg"
import { useForm } from "react-hook-form"
import moment from "moment"
import PrimaryButton from "../../components/Button/PrimaryButton"
import SecondaryButton from "../../components/Button/SecondaryButton"
import StatusPill from "../../components/StatusPill"
import Loading from "../../components/Loading"
import Jumbotron from "../../components/Jumbotron"
import ReactPaginate from "react-paginate"
import "./History.css"
import axiosGet from "../../helpers/axiosGet"

export default function History() {
  const dispatch = useDispatch()
  const showNotif = useSelector((state) => state.historyStore.showNotif)
  const history = useHistory()

  useEffect(() => {
    fetchHistories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const scroll = useRef(null)
  const executeScroll = () => scroll.current.scrollIntoView()

  const { register, handleSubmit } = useForm({
    defaultValues: {
      noPa: "",
      insured: "",
      paType: "",
      minTransactionDate: "",
      maxTransactionDate: "",
      status: "",
    },
  })

  const [values, setValues] = useState({
    noPa: "",
    insured: "",
    paType: "",
    minTransactionDate: "",
    maxTransactionDate: "",
    status: "",
  })

  const [open, setOpen] = useState(false)
  const [rows, setRows] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)

  const handleClose = () => {
    dispatch(setShowNotif(false))
  }

  const fetchHistories = (page, values) => {
    let halaman
    if (page === undefined) {
      halaman = 1
    } else {
      halaman = page
    }

    setLoading(true)

    axiosGet({
      url: `/policy/page/${halaman}?noPa=${values ? values.noPa : ""}&insured=${
        values ? values.insured : ""
      }&paType=${values ? values.paType : ""}&minTransactionDate=${
        values ? values.minTransactionDate : ""
      }&maxTransactionDate=${values ? values.maxTransactionDate : ""}&status=${
        values ? values.status : ""
      }`,
      callback: (res) => {
        setLoading(false)
        setRows(res.data.data)
        setTotalPage(res.data.totalPage)
      },
      errorCallback: (err) => {
        console.log(err)
        setLoading(false)
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("userCode")
        history.push("/404")
      },
    })
  }

  const handlePage = (value) => {
    setPage(value.selected)
    let halaman = value.selected + 1
    fetchHistories(halaman, values)
    executeScroll()
  }

  const searchHandler = (values) => {
    fetchHistories(1, values)
    setValues(values)
    setPage(0)
  }

  const resetValues = () => {
    fetchHistories(1)
    setPage(0)
    setValues({
      noPa: "",
      insured: "",
      paType: "",
      minTransactionDate: "",
      maxTransactionDate: "",
      status: "",
    })
  }

  return (
    <>
      <Jumbotron />
      <section className="create-main-content" ref={scroll}>
        <div className="text-center">
          <h3 className="font-weight-bold mb-1">RIWAYAT PEMBELIAN</h3>
          <hr className="create-divider-primary create-divider-big" />
        </div>

        <div className="border rounded mt-5 bg-white">
          <MDBContainer
            className="px-4 py-2"
            onClick={() => setOpen(!open)}
            style={{ cursor: "pointer" }}
          >
            <MDBRow>
              <MDBCol size="6" middle>
                <p className="mb-0">Search</p>
              </MDBCol>
              <MDBCol size="6" className="text-right">
                <MDBIcon icon={open ? "angle-up" : "angle-down"} size="lg" />
              </MDBCol>
            </MDBRow>
          </MDBContainer>

          {open && (
            <form onSubmit={handleSubmit(searchHandler)}>
              <MDBContainer className="border-top p-4 grey-text">
                <MDBRow className="create-row-gap">
                  <MDBCol md="4">
                    <label>No PA</label>
                    <input
                      placeholder="No PA"
                      name="noPa"
                      type="text"
                      className="form-control"
                      ref={register}
                    />
                  </MDBCol>
                  <MDBCol md="4">
                    <label>Insured</label>
                    <input
                      placeholder="Insured"
                      name="insured"
                      ref={register}
                      type="text"
                      className="form-control"
                    />
                  </MDBCol>
                  <MDBCol md="4">
                    <label>PA Type</label>
                    <select
                      className="browser-default custom-select"
                      ref={register}
                      name="paType"
                    >
                      <option value="">ALL</option>
                      <option value="PA-BASIC">PA BASIC</option>
                      <option value="PA-BASIC-COVID">
                        PA BASIC COVID PROTECTION
                      </option>
                    </select>
                  </MDBCol>
                  <MDBCol md="4">
                    <label>Transaction From</label>
                    <input
                      placeholder="Transaction From"
                      name="minTransactionDate"
                      ref={register}
                      type="date"
                      className="form-control"
                    />
                  </MDBCol>
                  <MDBCol md="4">
                    <label>Transaction To</label>
                    <input
                      placeholder="Transaction To"
                      name="maxTransactionDate"
                      ref={register}
                      type="date"
                      className="form-control"
                    />
                  </MDBCol>
                  <MDBCol md="4">
                    <label>Status</label>
                    <select
                      className="browser-default custom-select"
                      name="status"
                      ref={register}
                    >
                      <option value="">ALL</option>
                      <option value={0}>SUBMITTED</option>
                      <option value={100}>PAID</option>
                      <option value={300}>CANCEL</option>
                    </select>
                  </MDBCol>

                  <MDBCol md="12">
                    <MDBContainer>
                      <MDBRow end>
                        <SecondaryButton
                          text="RESET"
                          type="reset"
                          onClick={resetValues}
                          small
                        />
                        <div className="ml-3">
                          <PrimaryButton
                            text="SEARCH"
                            icon="search"
                            type="submit"
                            small
                          />
                        </div>
                      </MDBRow>
                    </MDBContainer>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </form>
          )}
        </div>

        <div className="mt-5 text-right">
          <Link to="/booking" onClick={() => window.scrollTo(0, 0)}>
            <PrimaryButton text="BOOKING" icon="plus" />
          </Link>
        </div>

        <div className="mt-3 text-center">
          {loading ? (
            <Loading />
          ) : rows.length ? (
            <>
              <DataTable rows={rows} executeScroll={executeScroll} />

              {totalPage > 1 && (
                <div className="mt-5 font-weight-bold">
                  <MDBContainer>
                    <MDBRow className="pagination-justify">
                      <ReactPaginate
                        previousLabel={<MDBIcon icon="chevron-left" />}
                        nextLabel={<MDBIcon icon="chevron-right" />}
                        breakLabel={"..."}
                        pageCount={totalPage}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        forcePage={page}
                        onPageChange={handlePage}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                    </MDBRow>
                  </MDBContainer>
                </div>
              )}
            </>
          ) : (
            <div className="font-weight-bold">NO DATA</div>
          )}
        </div>

        {showNotif && (
          <MDBNotification
            onClick={handleClose}
            show
            fade
            iconClassName="text-success"
            title="Success"
            message="Data submitted."
            style={{
              position: "fixed",
              top: "10px",
              right: "10px",
              zIndex: 9999,
              width: 300,
            }}
          />
        )}
      </section>
    </>
  )
}

const DataTable = ({ rows, executeScroll }) => {
  return (
    <MDBTable hover responsiveXl>
      <MDBTableHead>
        <tr>
          <th>NO PA</th>
          <th>INSURED</th>
          <th style={{ minWidth: 250 }}>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={Calendar}
                alt="calendar"
                className="mr-2"
                width="20"
              ></img>
              PERIOD
            </div>
          </th>
          <th style={{ minWidth: 100 }}>PA TYPE</th>
          <th style={{ minWidth: 200 }}>
            <div className="d-flex justify-content-center align-items-center">
              <img
                src={Calendar}
                alt="calendar"
                className="mr-2"
                width="20"
              ></img>
              Transaction Date
            </div>
          </th>
          <th style={{ minWidth: 160 }}>STATUS</th>
          <th>ACTION</th>
        </tr>
      </MDBTableHead>
      <MDBTableBody>
        {rows.map((row) => {
          return (
            <tr key={row.id}>
              <td>{row.policyNumber}</td>
              <td>{row.insuredName}</td>
              <td>
                <img
                  src={Calendar}
                  alt="calendar"
                  className="mr-2"
                  width="20"
                ></img>
                {moment(row.policyStartDate).format("DD-MM-YYYY")} -{" "}
                {row.policyEndDate
                  ? moment(row.policyEndDate).format("DD-MM-YYYY")
                  : "null"}
              </td>
              <td>
                {row.policyType === "PA-BASIC" ? (
                  "PA BASIC"
                ) : (
                  <span>
                    PA BASIC <br /> COVID PROTECTION
                  </span>
                )}
              </td>
              <td>
                <img
                  src={Calendar}
                  alt="calendar"
                  className="mr-2"
                  width="20"
                ></img>
                {moment(row.transactionDate).format("DD-MM-YYYY HH:mm:ss")}
              </td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <StatusPill value={row.status}></StatusPill>
                </div>
              </td>
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <Link
                    to={() => {
                      const encode = btoa(row.id)
                      return `/history/${encode}`
                    }}
                    className="create-bg-primary table-action-button"
                    onClick={executeScroll}
                  >
                    <MDBIcon
                      icon="chevron-right"
                      size="lg"
                      className="white-text"
                    />
                  </Link>
                </div>
              </td>
            </tr>
          )
        })}
      </MDBTableBody>
    </MDBTable>
  )
}
