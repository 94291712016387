import React from "react"
import "./Button.css"

export default function SecondaryButton({ text, small, type, onClick }) {
  const styles = (small) => {
    return small ? "root secondary small" : "root secondary"
  }

  return (
    <button className={styles(small)} type={type} onClick={onClick}>
      {text}
    </button>
  )
}
