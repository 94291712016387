import React from "react"
import { Link } from "react-router-dom"
import PrimaryButton from "../../components/Button/PrimaryButton"
import Background from "../../img/Group871.png"
import Ballons from "../../img/ballons.svg"
import Triangle from "../../img/triangle.svg"
import "./404.css"

export default function NotFound() {
  return (
    <section className="not-found">
      <img src={Background} alt="background" className="noroute-bg" />
      <img src={Ballons} alt="ballons" className="noroute-ballons" />
      <img src={Triangle} alt="triangle" className="noroute-triangle" />
      <h1 className="heading">404</h1>
      <h2 className="subheading">Halaman Tidak Ditemukan</h2>
      <p className="paragraph">
        Alamat yang anda masukkan mungkin salah atau anda tidak mempunyai hak
        akses kepada halaman tersebut.
      </p>

      <Link to="/history">
        <PrimaryButton text="Home" />
      </Link>
    </section>
  )
}
