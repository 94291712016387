import {
  MDBContainer,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
} from "mdbreact";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import Detail from "../../components/Detail";
import Jumbotron from "../../components/Jumbotron";
import Loading from "../../components/Loading";
import axiosPost from "../../helpers/axiosPost";
import { resetDataBooking, setShowNotif } from "../../store/actions";
import constants from "../../constants";

export default function SummaryPublic() {
  const scroll = useRef(null);
  const executeScroll = () => scroll.current.scrollIntoView();

  const booking = useSelector((state) => state.bookingStore.dataBooking);
  const summary = useSelector((state) => state.bookingStore.dataSummary);
  const data = { ...booking, ...summary };

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, setValue, clearErrors } = useForm({
    defaultValues: {
      agreement: false,
    },
  });

  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const handleTerms = () => {
    setModal(!modal);
  };

  const handleAgree = () => {
    setValue("agreement", true);
    setModal(false);
    clearErrors("agreement");
  };

  const submitHandler = () => {
    executeScroll();

    const fd = {
      userCode: data.insuredPhoneNumber,
      username: data.insuredName,
      insuredType: data.insuredType,
      insuredIdentifyNumber: data.insuredIdentifyNumber,
      insuredName: data.insuredName,
      insuredAddress: data.insuredAddress,
      insuredBirthPlace: data.insuredBirthPlace,
      insuredBirthDate: data.insuredBirthDate,
      insuredGender: data.insuredGender,
      insuredPhoneNumber: data.insuredPhoneNumber,
      insuredEmail: data.insuredEmail,
      insuredHeirName: data.insuredHeirName,
      policyType: "PA-BASIC",
      policyStartDate: data.policyStartDate,
      sumInsured: 100000000,
      province: data.province.value || null,
    };

    setLoading(true);

    axiosPost({
      url: `${constants.DEFAULT_URL_PUBLIC}/v2/open/personal-accident/booking`,
      data: fd,
      callback: (res) => {
        setLoading(false);
        dispatch(setShowNotif(true));
        dispatch(resetDataBooking(true));
        sessionStorage.removeItem("isPublic");
        window.location.replace(res.data.url);
      },
      errorCallback: (res) => {
        setLoading(false);
        if (res.statusCode === 400) {
          console.log(res.message);
        } else {
          console.log(res);
        }
      },
    });
  };

  return (
    <>
      <Jumbotron />
      <section className="create-main-content" ref={scroll}>
        <div className="text-center">
          <h3 className="font-weight-bold">SUMMARY</h3>
          <p className="mb-1">SCHEDULE PERSONAL ACCIDENT CO-PRODUCT</p>
          <hr className="create-divider-primary create-divider-big" />
        </div>

        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="mt-5">
              <p className="mb-1">Detail of Insured</p>
              <hr className="create-divider-primary create-divider-small" />
            </div>

            <Detail data={data} />

            <div className="mt-5">
              <form onSubmit={handleSubmit(submitHandler)}>
                <div className="custom-control custom-checkbox mb-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="agreementCheckbox"
                    name="agreement"
                    ref={register({ required: true })}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="agreementCheckbox"
                  >
                    <a
                      href="#modal"
                      onClick={(e) => {
                        e.preventDefault();
                        handleTerms();
                      }}
                      style={{
                        textDecoration: "underline",
                        fontWeight: "normal",
                      }}
                    >
                      I agree to this Microsite Term of Service and Conditions
                    </a>
                  </label>
                  {errors.agreement && (
                    <p className="create-error-message">*You must agree</p>
                  )}
                </div>

                <MDBContainer className="create-button-container">
                  <MDBRow between>
                    <Link to="/booking-policy" onClick={executeScroll}>
                      <SecondaryButton text="Back" />
                    </Link>
                    <PrimaryButton
                      loading={loading}
                      text="Submit"
                      type="submit"
                    />
                  </MDBRow>
                </MDBContainer>
              </form>
            </div>

            <MDBModal
              isOpen={modal}
              toggle={handleTerms}
              centered
              fade={false}
              id="modal"
            >
              <MDBModalHeader toggle={handleTerms}>
                {`Term & Conditions`}
              </MDBModalHeader>
              <MDBModalBody>
                This microsite is developed and powered by PT. Pialang Asuransi
                Provis Mitra Sinergi as a form of collaboration and cooperation
                regarding Personal Accident Insurance of PT Great Eastern
                General Insurance Indonesia. <br />
                <br />
                The information contained in this microsite is for general
                information purpose only, and the data information inputted in
                this microsite is solely used for purchase of such product
                purpose. <br />
                <br />
                As an insurance broker, we make representations of our clients
                however we make no promises and/or warranties of any kind
                herein, express or implied, about the completeness, accuracy,
                reliability, suitability or availability with respect to the
                data information inputted in microsite or the information,
                products, services on this microsite for any purpose. Any
                reliance you place on such information in this microsite is
                therefore strictly at your own risk. <br />
                <br />
                In no event will PT. Pialang Asuransi Provis Mitra Sinergi be
                not liable for any loss or damage including without limitation,
                indirect or consequential loss or damage, or any loss or damage
                whatsoever arising from fail in performing PT Great Eastern
                General Insurance Indonesia obligation and/or commit the
                negligence and/or violates the provisions in accordance with the
                Policy, arising out of, or in connection with, the Personal
                Accident Product itself, as PTGreat Eastern General Insurance
                Indonesia is the product owner.
              </MDBModalBody>
              <MDBModalFooter>
                <PrimaryButton text="I Agree" onClick={handleAgree} />
              </MDBModalFooter>
            </MDBModal>
          </>
        )}
      </section>
    </>
  );
}
