import React, { useEffect, useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home/Home";
import Booking from "./pages/Booking/Booking";
import Summary from "./pages/Summary/Summary";
import History from "./pages/History/History";
import HistoryDetail from "./pages/HistoryDetail/HistoryDetail";
import NotFound from "./pages/404/404";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { MDBContainer } from "mdbreact";
import { useSelector } from "react-redux";
import BookingPublic from "./pages/BookingPublic/BookingPublic";
import SummaryPublic from "./pages/SummaryPublic/SummaryPublic";
import PublicDetail from "./pages/PublicDetail/PublicDetail";
import Failed from "./pages/Status/Failed";

function App() {
  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const userCode = sessionStorage.getItem("userCode");

    setToken(token);
    setUserCode(userCode);
  }, []);

  const [token, setToken] = useState(null);
  const [userCode, setUserCode] = useState(null);

  const dataSummary = useSelector((state) => state.bookingStore.dataSummary);

  return (
    <Router>
      <Navbar isToken={token} />

      <MDBContainer>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route exact path="/history">
            {token && userCode ? <History /> : <NotFound />}
          </Route>

          <Route exact path="/history/:id">
            {token && userCode ? <HistoryDetail /> : <NotFound />}
          </Route>

          <Route exact path="/booking">
            {token && userCode ? <Booking /> : <NotFound />}
          </Route>

          <Route exact path="/summary">
            {token && userCode ? (
              dataSummary ? (
                <Summary />
              ) : (
                <Redirect to="/booking" />
              )
            ) : (
              <NotFound />
            )}
          </Route>

          <Route exact path="/booking-policy">
            <BookingPublic />
          </Route>

          <Route exact path="/summary-policy">
            {dataSummary ? (
              <SummaryPublic />
            ) : (
              <Redirect to="/booking-policy" />
            )}
          </Route>

          <Route exact path="/policy/:uniqKey">
            <PublicDetail />
          </Route>

          <Route exact path="/failed">
            <Failed />
          </Route>

          <Route>
            <NotFound />
          </Route>
        </Switch>
      </MDBContainer>

      <Footer />
    </Router>
  );
}

export default App;
