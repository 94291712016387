const initialState = {
  showNotif: false,
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SHOW_NOTIF":
      return {
        ...state,
        showNotif: action.payload,
      };
    // case "RESET_DATA_HISTORY":
    //   return (state = initialState);
    default:
      return state;
  }
};

export default historyReducer;
