import React from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "../../components/Button/PrimaryButton";
import Background from "../../img/Group871.png";
import Ballons from "../../img/ballons.svg";
import Triangle from "../../img/triangle.svg";
import PaymentFailed from "../../img/payment-failed.jpg";
import "../404/404.css";

export default function Failed() {
  return (
    <section className="not-found">
      <img src={Background} alt="background" className="noroute-bg" />
      <img src={Ballons} alt="ballons" className="noroute-ballons" />
      <img src={Triangle} alt="triangle" className="noroute-triangle" />
      <img src={PaymentFailed} alt="payment-failed" width="300" />
      {/* <h1 className="heading">404</h1> */}
      <h2 className="subheading">Payment Failed</h2>
      <p className="paragraph">
        Sorry, your payment failed. Please try again your payment process.
      </p>

      <Link to="/booking-policy">
        <PrimaryButton text="Booking" />
      </Link>
    </section>
  );
}
