// user store
export const setUserType = (payload) => {
  return {
    type: "SET_USER_TYPE",
    payload,
  };
};

// booking store
export const setDataBooking = (payload) => {
  return {
    type: "SET_DATA_BOOKING",
    payload,
  };
};

export const setDataSummary = (payload) => {
  return {
    type: "SET_DATA_SUMMARY",
    payload,
  };
};

export const setDataFoto = (payload) => {
  return {
    type: "SET_DATA_FOTO",
    payload,
  };
};

export const resetDataBooking = (payload) => {
  return {
    type: "RESET_DATA_BOOKING",
    payload,
  };
};

// history store
export const setShowNotif = (payload) => {
  return {
    type: "SET_SHOW_NOTIF",
    payload,
  };
};
