import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavbarBrand,
  MDBNavbarNav,
} from "mdbreact";
import Logo from "../img/polismall.png";

export default function Navbar({ isToken }) {
  const location = useLocation();

  useEffect(() => {}, [isToken]);

  const [collapse, setCollapse] = useState(false);

  const toggleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <MDBNavbar className="z-depth-0" expand="md" dark>
      <MDBNavbarBrand>
        <Link to={isToken ? "/history" : "/booking-policy"}>
          <img src={Logo} alt="polismall" />
        </Link>
      </MDBNavbarBrand>

      <MDBNavbarToggler onClick={toggleCollapse} />
      <MDBCollapse isOpen={collapse} navbar>
        <MDBNavbarNav right>
          <MDBNavItem
            active={
              location.pathname === "/booking" ||
              location.pathname === "/summary"
            }
            className="font-weight-bold"
            onClick={() => setCollapse(false)}
          >
            <Link
              to={isToken ? "/booking" : "/booking-policy"}
              className="nav-link"
            >
              Booking
            </Link>
          </MDBNavItem>
          {isToken && (
            <MDBNavItem
              active={
                location.pathname !== "/booking" &&
                location.pathname !== "/summary"
              }
              className="font-weight-bold"
              onClick={() => setCollapse(false)}
            >
              <Link to="/history" className="nav-link">
                History
              </Link>
            </MDBNavItem>
          )}
        </MDBNavbarNav>
      </MDBCollapse>
    </MDBNavbar>
  );
}
